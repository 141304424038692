<template>
    <b-row class="autoFillBtn">
        <Avatar size="40" :src="patient?.picture" :status="appointment?.status" />
        <b-col class="patient-informations">
            <div class="vertical-align-middle inline-overflow name-width">
                <Ellipsis>
                    {{ patient?.name }}
                </Ellipsis>
            </div>
            <div class="row">
                <div class="cpf-container">
                    <div v-if="patient?.cpf" class="cpf">{{ patient?.cpf }}</div>
                </div>
            </div>
        </b-col>
        <div class="icon-container">
            
            <Icon tooltip="Prontuário">
                <ExternalLink @click="openPatientDashboard" class="icon" />
            </Icon>
        </div>
    </b-row>
</template>
<script>
import moment from 'moment';

export default {
    name: 'PatientInformation',
    components: {
        Icon: () => import('@/components/General/Icon'),
        Avatar: () => import('@/components/General/Avatar'),
        Ellipsis: () => import('@/components/General/Ellipsis'),
        ExternalLink: () => import('@/assets/icons/external-link.svg')

    },
    props: {
        appointment: {
            type: Object,
            required: false
        },
        patient: {
            type: Object,
            required: true
        }
    },

    methods: {
        getBirthdayDate(birthday) {
            if (!birthday) return '-';
            return moment(birthday).format('DD/MM/YYYY');
        },

        getAge(birthday) {
            const days = moment().diff(moment(birthday), 'days');
            const months = moment().diff(moment(birthday), 'month');
            const years = moment().diff(moment(birthday), 'years');

            if (!birthday) {
                return '-';
            } else if (months > 1 && months < 12) {
                return `(${months} meses)`;
            } else if (days <= 30) {
                return `(${days} dias)`;
            } else {
                return `${years} anos`;
            }
        },
        openPatientDashboard() {
            window.open(`/pacientes/${this.patient.id}`, '_blank');
        },
    }
};
</script>
<style lang="scss" scoped>
.autoFillBtn {
    padding-left: 8px !important;
    margin-bottom: 2px !important;
}

.patient-informations {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex: 0.7;

    .row {
        display: flex;
        flex-direction: row !important;
        margin-left: 0px;
        margin-right: 0px;
        justify-content: space-between;

        @media (max-width: 820px) {
            justify-content: center !important;
        }

        .cpf-container {
            display: flex;
            flex-direction: row;

            .cpf {
                color: #305BF2;
            }
        }



    }

    .divisor {
        margin: 0 5px;
        color: #d9dff2;

        @media screen and (max-width: 1280px) {
            color: white;
        }
    }



}
.icon-container {
    display: flex;
    align-items: center;
    justify-content: start;
    flex: 0.3;

    .icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        stroke: #305BF2;
    }
}
</style>